<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">进销存</el-breadcrumb-item>
        <el-breadcrumb-item>入库单详情</el-breadcrumb-item>
      </el-breadcrumb>
    </template>

    <div class="toolbar">
      <el-button type="info" icon="Back" @click="back()">返回</el-button>
      <el-button type="primary" icon="printer" @click="onprint('0')">打印预入库单</el-button>
      <el-button type="success" icon="printer" @click="onprint('1')">打印入库接收单</el-button>
    </div>

    <section ref="print" id="print-panel">
      <div class="title-box">
        <h3 style=" font-size: 1.6em; font-weight: 500; text-align: center;  margin: 20px auto; margin-top: 30px;">
          [{{ bill.billType }}] 入库通知单
        </h3>
        <el-row :gutter="10" style="padding: 12px">
          <el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14">
            <span style="margin-right: 20px">
              所属公司：
              <!-- <el-cascader v-model="bill.companyID" :options="companys" style="max-width:150px" :disabled="true" /> -->
              <el-tree-select ref="companys" v-model="bill.companyID" :data="companys" disabled check-strictly :render-after-expand="false" style="width: 200px;" />
            </span>
            <span style=" margin-right: 20px"> 类型：{{ bill.billType }} </span>
            <span> 单号：{{ bill.billCode }} </span>
          </el-col>

          <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10" align="right">
            <span style="margin-right: 30px"> 关联单号：{{ bill.netCode || "无" }} </span>
            <span> 时间：{{ bill.billDate }} </span>
          </el-col>
        </el-row>
      </div>

      <el-tabs type="border-card" v-model="tabName">
        <el-tab-pane name="prestock">
          <template #label>
            <span>预入库货品</span>
          </template>

          <el-form :inline="true">
            <el-form-item label="货品条码">
              <el-input v-model="forms.keyword" @keyup.enter="auditBound" placeholder="扫码/回车键自动审核">
              </el-input>
            </el-form-item>
            <el-form-item label="数量">
              <el-input-number v-model="forms.number" @keyup.enter="auditBound" :precision="2" :step="1" :min="0"
                :max="10000" />
            </el-form-item>

            <el-form-item label="" label-width="0px" v-if="forms.keyword">
              <el-button type="primary" icon="CircleCheck" @click="auditBound">扫码审核</el-button>
            </el-form-item>

            <el-form-item label="" label-width="0" v-if="selects.length > 0">
              <el-button type="primary" icon="Select" @click="auditBatch">批量审核</el-button>
            </el-form-item>

            <el-form-item label="" label-width="0" v-if="selects.length > 0" style="float:right;">
              <el-button type="danger" icon="Select" @click="refuseBatch">取消审核</el-button>
            </el-form-item>
          </el-form>

          <el-table class="table" :data="prebounds" border style="width: 100%" highlight-current-row size="small"
            show-summary @selection-change="selectionChange">
            <el-table-column type="selection" width="50" align="center" />
            <el-table-column label="序号" type="index" width="50" align="center"> </el-table-column>
            <el-table-column prop="channel" label="渠道" width="70" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="storeName" label="库房" width="70" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="status" label="状态" align="center" width="60">
              <template #default="scope">
                <el-link v-if="scope.row.status == 0" type="primary" size="small" disabled
                  title="待确认(未入库)">待确认</el-link>
                <el-link v-else-if="scope.row.status == 1" type="success" size="small" title="已确认(已入库)"
                  disabled>已确认</el-link>
                <el-link v-else-if="scope.row.status == -1" type="danger" size="small" title="已取消"
                  disabled>已取消</el-link>
                <el-link v-else type="info" size="small" disabled>其他</el-link>
              </template>
            </el-table-column>
            <el-table-column prop="brandSerialCode" label="批次号" align="center" sortable width="120">
              <template #default="scope">
                <el-link v-if="scope.row.status == 0" type="primary" size="small" disabled title="待确认(未入库)">{{
                  scope.row.brandSerialCode }}</el-link>
                <el-link v-else-if="scope.row.status == 1" type="success" size="small" title="已确认(已入库)" disabled>{{
                  scope.row.brandSerialCode }}</el-link>
                <el-link v-else-if="scope.row.status == -1" type="danger" size="small" title="已取消" disabled>{{
                  scope.row.brandSerialCode }}</el-link>
                <el-link v-else type="info" size="small" disabled>{{ scope.row.brandSerialCode }}</el-link>
              </template>
            </el-table-column>
            <el-table-column prop="detailCode" label="系统款号" width="130" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="brandModelCode" label="品牌款号" width="120" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="certNumber" label="证书号" width="130" align="center" show-overflow-tooltip>
            </el-table-column>

            <el-table-column prop="detailName" label="名称" sortable width="180" align="center" show-overflow-tooltip>
            </el-table-column>

            <el-table-column prop="brandCode" label="供应商" width="70" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="detailCode" label="图片" align="center" width="70">
              <template #default="scope">
                <el-image style="height: 32px; width: 32px" lazy
                  :src="apiUrl + 'static/code/' + scope.row.detailCode + '?t=0'"
                  :preview-src-list="[apiUrl + 'static/code/' + scope.row.detailCode + '?t=1']">
                  <template #error><img style="width: 100%" src="~@/assets/imgunfine.jpg" alt="" /></template>
                </el-image>
              </template>
            </el-table-column>

            <el-table-column prop="stockNumber" label="数量" align="right" width="55"> </el-table-column>
            <el-table-column prop="labelPrice" label="标签价" align="right" width="65"> </el-table-column>

            <el-table-column prop="weight" label="克重" align="right" width="65"> </el-table-column>
            <el-table-column prop="unitName" label="规格" align="center" width="65"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="noTaxPurchasePrice" width="95" label="不含税采购价" align="right"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="taxPrice" width="70" label="税额" align="right"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="purchasePrice" width="90" label="含税采购价" align="right"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="productPrice" width="75" align="right" label="入库价"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="sellPrice" width="75" align="right" label="售价"> </el-table-column>
            <el-table-column prop="outOrderNo" label="原单号" width="120" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="remark" label="备注" show-overflow-tooltip> </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane name="instock">
          <template #label>
            <span>已审核货品</span>
          </template>

          <el-table class="table" :data="bounds" border style="width: 100%" highlight-current-row size="small"
            show-summary>
            <el-table-column label="序号" type="index" width="50" align="center"> </el-table-column>
            <el-table-column prop="channel" label="渠道" width="70" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="storeCode" label="库房" width="70" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="detailCode" label="系统款号" width="130" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="brandModelCode" label="品牌款号" width="120" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="certNumber" label="证书号" width="130" align="center" show-overflow-tooltip>
            </el-table-column>

            <el-table-column prop="detailName" label="名称" sortable width="180" align="center" show-overflow-tooltip>
            </el-table-column>

            <el-table-column prop="brandCode" label="供应商" width="70" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="brandSerialCode" label="批次号" align="center" sortable width="120"> </el-table-column>
            <el-table-column prop="detailCode" label="图片" align="center" width="70">
              <template #default="scope">
                <el-image style="height: 32px; width: 32px" lazy
                  :src="apiUrl + 'static/code/' + scope.row.detailCode + '?t=0'"
                  :preview-src-list="[apiUrl + 'static/code/' + scope.row.detailCode + '?t=1']">
                  <template #error><img style="width: 100%" src="~@/assets/imgunfine.jpg" alt="" /></template>
                </el-image>
              </template>
            </el-table-column>
            <el-table-column prop="stockNumber" label="数量" align="right" width="55"> </el-table-column>
            <el-table-column prop="labelPrice" label="标签价" align="right" width="65"> </el-table-column>

            <el-table-column prop="weight" label="克重" align="right" width="65"> </el-table-column>
            <el-table-column prop="unitName" label="规格" align="center" width="65" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="noTaxPurchasePrice" width="95" label="不含税采购价" align="right"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="taxPrice" width="70" label="税额" align="right"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="purchasePrice" width="90" label="含税采购价" align="right"
              show-overflow-tooltip></el-table-column>
            <el-table-column prop="productPrice" width="75" align="right" label="入库价"> </el-table-column>
            <el-table-column prop="sellPrice" width="75" align="right" label="售价"> </el-table-column>
            <el-table-column prop="outOrderNo" label="原单号" width="120" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="remark" label="备注" show-overflow-tooltip> </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>

    </section>
  </el-card>
</template>
<script>
import printJS from "print-js";
import { fetchBillByCode, auditBoundIn, refuseBoundIn } from "@/api/stock";
export default {
  name: "billInDetail",
  components: {},
  data() {
    return {
      apiUrl: '',
      billCode: null,
      bill: {},
      bounds: [],
      prebounds: [],

      forms: {
        keyword: '',
        number: 1,
      },
      tabName: "prestock",
      dict: {
        inBillTypes: [],
      },

      companys: [],
      companyName: '',
      selects: [],
    };
  },
  methods: {
    loadData() {
      fetchBillByCode(this.billCode).then((res) => {
        let that = this;
        if (res.code == 200) {
          this.bill = res.data.bill;
          this.companyName = this.companys.filter(function (q) { return q.id == that.bill.companyID })[0].companyName;

          this.bounds = res.data.bounds;
          this.prebounds = res.data.prebounds;
          this.title = res.data.title;

          //   var sumNumber = 0; var sumWeight = 0; var sumPurchasePrice = 0;
          //   for (let i = 0; i < this.bounds.length; i++) {
          //     sumNumber += parseFloat(this.bounds[i].stockNumber);
          //     sumWeight += parseFloat(this.bounds[i].weight);
          //     sumPurchasePrice += parseFloat(this.bounds[i].purchasePrice);
          //   }
          //   this.bounds.push({
          //     channel: '【合计】',
          //     stockNumber: sumNumber.toFixed(2),
          //     weight: sumWeight.toFixed(2),
          //     purchasePrice: sumPurchasePrice.toFixed(2),
          //   });


          //   var sumNumber1 = 0; var sumWeight1 = 0; var sumPurchasePrice1 = 0;
          //   for (let i = 0; i < this.prebounds.length; i++) {
          //     sumNumber1 += parseFloat(this.prebounds[i].stockNumber);
          //     sumWeight1 += parseFloat(this.prebounds[i].weight);
          //     sumPurchasePrice1 += parseFloat(this.prebounds[i].purchasePrice);
          //   }
          //   this.prebounds.push({
          //     channel: '【合计】',
          //     stockNumber: sumNumber1.toFixed(2),
          //     weight: sumWeight1.toFixed(2),
          //     purchasePrice: sumPurchasePrice1.toFixed(2),
          //   })
        }
      });
    },
    back() {
      this.$router.go(-1);
    },
    //打印
    onprint(printType) {
      // let noprints = document.getElementsByClassName("caret-wrapper");
      // for (let i = 0; i < noprints.length; i++) {
      //   noprints[i].style.display = "none";
      // }

      // printJS({
      //   printable: "print-panel",
      //   type: "html",
      //   style:
      //     ".el-table td, .el-table th.is-leaf { border-bottom:1px solid #EBEEF5; }",
      // });


      //console.log(this.bill.companyId)
      let printData = [];

      let dataitems = [];
      if (printType == '1') dataitems = this.bounds;
      else if (printType == '0') dataitems = this.prebounds;

      let total = 0;
      for (let i = 0; i < dataitems.length; i++) {
        let row = dataitems[i];
        if ((row.detailName || '') == '') continue;

        total += row.stockNumber;
        printData.push({
          '序号': (i + 1),
          '渠道': row.channel,
          '库房': row.storeName || row.storeCode,
          '系统款号': row.detailCode,
          '品牌款号': (row.brandModelCode || ''),
          '批次号': (row.brandSerialCode || ''),
          '名称': this.spitOmit(row.detailName || '', 15),
          '数量': row.stockNumber,
          '克重': row.weight,
          '规格': row.unitName,
          '备注': (row.remark || '')
        });
      }

      let title = this.companyName + "<span style='float:right'>" + this.bill.billDate + "</span>" + "<br>";
      title += '[' + this.bill.billType + ']';

      if (printType == '1') title += " 正式入库单 ";
      else if (printType == '0') title += " 预入库单 ";

      title += "(货品" + dataitems.length + "件,合计" + total + "个) " + "<span style='float:right'>" + this.bill.billCode + "</span>";
      title += "<br>";

      title += "<span style='font-size:0.5em;float:right; margin-bottom:10px;'>";
      title += "[操作： " + (this.bill.handoverName || this.bill.createUserName) + "]、";
      title += "[交接： " + (this.bill.reviewerName || "无") + "]、";
      title += "[复核： " + (this.bill.operatorName || "无") + "]、";
      title += "[财务： " + (this.bill.financerName || "无") + "] ";
      title += "</span>";

      printJS({
        header: '<h3 class="">' + title + '</h3>',
        printable: printData,
        properties: ['序号', '渠道', '库房', '系统款号', '品牌款号', '批次号', '名称', '数量', '克重', '规格', '备注'],
        type: 'json',
        gridHeaderStyle: 'font-size:0.4em;font-weight:bold;border: 1px solid #ccc;white-space:nowrap',
        gridStyle: 'padding:1px 2px;',
        style: 'table tr td{font-size:0.4em;border: 1px solid #ccc;text-align:center;white-space:nowrap }'
      })
    },
    spitBr(str, len) {
      let restr = '';
      len = len || 10;
      let i = 1;
      while (str.length > len) {
        restr += str.substr(0, len) + '<br>';
        str = str.substr(len, str.length);
        i++;
      }
      restr += str;
      return restr;
    },
    spitOmit(str, len) {
      return (str || '').length > (len || 10) ? str.substr(0, len || 10) + "…" : str;
    },
    auditBound() {
      let that = this;
      if (!this.forms.keyword || this.forms.number <= 0) {
        this.$message.error("审核失败，请输入正确的货号及数量");
        return;
      }
      let details = this.prebounds.filter(function (q) { return q.brandSerialCode == that.forms.keyword || q.serialCode == that.forms.keyword });
      if (details.length == 0) {
        this.$message.error("审核失败，未找到该货品批次号");
        return;
      }
      details = details.filter(function (q) { return q.stockNumber == that.forms.number });
      if (details.length == 0) {
        this.$message.error("审核失败，该货品批次号对应数量错误，请检查入库数量和实际货品数量");
        return;
      }
      let detail = details[0];
      let params = [{
        brandSerialCode: detail.brandSerialCode,
        serialCode: detail.serialCode,
        stockNumber: detail.stockNumber,
        id: detail.id
      }];
      auditBoundIn(this.billCode, params).then((res) => {
        that.$message({ type: res.type, message: res.content, });
        if (res.code == 200) {
          that.forms.keyword = '';
          that.forms.number = 1;
          that.loadData();
        }
      });
    },
    auditBatch() {
      let that = this;
      let rows = this.selects.filter(function (q) { return q.status == 0; });
      if (rows.length == 0) {
        this.$message.error("审核失败，请勾选未审核货品");
        return;
      }

      let params = [];
      rows.forEach(function (item) {
        params.push({
          brandSerialCode: item.brandSerialCode,
          serialCode: item.serialCode,
          stockNumber: item.stockNumber,
          id: item.id
        });
      });
      auditBoundIn(this.billCode, params).then((res) => {
        that.$message({ type: res.type, message: res.content, });
        if (res.code == 200) {
          that.forms.keyword = '';
          that.forms.number = 1;
          that.loadData();
        }
      });
    },
    refuseBatch() {
      let that = this;
      let rows = this.selects.filter(function (q) { return q.status == 0; });
      if (rows.length == 0) {
        this.$message.error("审核失败，请勾选未审核货品");
        return;
      }

      let params = [];
      rows.forEach(function (item) {
        params.push({
          id: item.id
        });
      });
      refuseBoundIn(this.billCode, params).then((res) => {
        that.$message({ type: res.type, message: res.content, });
        if (res.code == 200) {
          that.forms.keyword = '';
          that.forms.number = 1;
          that.loadData();
        }
      });
    },
    selectionChange(rows) {
      this.selects = rows;
    }
  },
  mounted() { },
  created() {
    this.apiUrl = process.env.VUE_APP_BASEURL;

    this.$kaung.dictionary("stock", "inbound").then((res) => {
      this.dict.inBillTypes = res;
    });

    this.$kaung.companys().then((res) => {
      this.companys = res;
    });

    this.billCode = this.$route.query.billCode;
    this.loadData();
  },
  watch: {

  },
};
</script>
<style scoped>
.title-box {
  border: 1px solid #dcdfe6;
  border-bottom: 0;
}
</style>